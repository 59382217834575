import React, { useContext } from "react";
import { GlobalDataContext } from "../../context/context";
import { ButtonContent } from "../global/boton/ButtonContent";

function HeroSection() {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <>
      <div className="pic-wrapper">
        <figure
          className="pic-1"
          style={{ background: `url("${rpdata?.gallery?.length > 20 ? rpdata?.gallery?.[15] : rpdata?.stock?.[0]}")` }}
        ></figure>
        <figure
          className="pic-2"
          style={{ background: `url("${rpdata?.gallery?.length > 20 ? rpdata?.gallery?.[10] : rpdata?.stock?.[1]}")` }}
        ></figure>
        <figure
          className="pic-3"
          style={{ background: `url("${rpdata?.gallery?.length > 20 ? rpdata?.gallery?.[2] : rpdata?.stock?.[2]}")` }}
        ></figure>
        <figure
          className="pic-4"
          style={{ background: `url("${rpdata?.gallery?.length > 20 ? rpdata?.gallery?.[4] : rpdata?.stock?.[3]}")` }}
        ></figure>
        <div className="z-10 relative w-4/5 mx-auto pt-[380px] pb-[20px] md:pb-[110px] md:pt-[500px] ">
          <div className="w-full flex flex-col justify-center items-center">
            <h1 className="text-white text-center ">
              {rpdata?.dbSlogan?.[0].slogan}
            </h1>
            <p className="text-white text-center ">{rpdata?.dbValues?.[0].description}</p>
            <div className="flex justify-center w-full">
              <ButtonContent />
            </div>
          </div>
          {/* <div className="w-auto h-auto flex justify-center items-center">
            <img
              src={"https://firebasestorage.googleapis.com/v0/b/imagenes-fc412.appspot.com/o/Años%20de%20experiencia%2FEXPERIENCIA_Mesa%20de%20trabajo%201.png?alt=media&token=a3a165eb-45aa-4265-8d99-71598e10e612"}
              alt='no found'
              loading='lazy'
              className='w-[200px] h-[200px] md:w-[500px] md:h-[400px]'
            />
          </div> */}
        </div>
      </div>
    </>
  );
}

export default HeroSection;