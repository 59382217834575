import React, { useContext } from "react";
import { GlobalDataContext } from "../../context/context";
import { ButtonContent } from "../global/boton/ButtonContent";



const Block_11 = ({ title, text, sloganPrincipal }) => {
    const { rpdata } = useContext(GlobalDataContext);
    return (
        <section className="w-full flex md:flex-row flex-col justify-center md:py-0">
            <div className="flex justify-center md:w-1/2 w-full h-auto">
                <img src={rpdata?.stock?.[0]} alt="img" className="w-[100%] h-auto" />
            </div>
            <div className="md:w-1/2 w-full h-auto bg-2 flex items-center justify-center">
                <div className="text-center py-5">
                    {
                        sloganPrincipal ?
                            rpdata?.dbPrincipal?.licensed.length > 1 ?
                                <h2 className='pb-3 capitalize'>
                                    {rpdata?.dbPrincipal?.licensed}
                                </h2>
                                : <h2 className='pb-3 capitalize'>we have {rpdata?.dbPrincipal?.exprYears} years of experience </h2>
                            :
                            <h3 className='pb-3 capitalize text-start px-[8%] text-white'>
                                {
                                    title ? title :
                                        <span className='lowercase text-[18px]'>
                                            {`default title={'frase'}`} o
                                            {` agregar licencia o años de experienceias sloganPrincipal={true}`}
                                        </span>
                                }
                            </h3>
                    }
                    <p className='pb-3 text-start text-white px-[8%]'>
                        {
                            text ? text :
                                <span>{`para agregar el texto -> text={'description'}`}
                                    <br />{'para agregar lista de about -> listsAbout={true}'}
                                    <br />{'para agregar lista de servicios -> listsServices={true}'}
                                </span>
                        }
                    </p>
                    <div className="flex md:flex-row  py-3">
                        
               

                    </div>
                    <div className="flex px-16">
                        <ButtonContent />
                    </div>
                </div>
            </div>
        </section>
    );

}

export default Block_11;