import React, { useContext } from 'react'
import { BsArrowRightCircleFill } from 'react-icons/bs'
import imgDefault from '../../assets/image/placeholder.png'
import { GlobalDataContext } from '../../context/context';

const Values_5 = ({ image1, image2, image3 }) => {
    const { rpdata } = useContext(GlobalDataContext);
    return (
        <>
            <div className='w-full h-auto my-12 md:flex md:flex-row flex-col'>
                <div
                    className='w-full md:w-[40%] h-[65vh] md:h-[90vh] border bg-black relative bg-contain bg-center rounded-br-3xl rounded-tr-3xl'
                    style={{ background: `url("${image1 ? image1 : imgDefault}")`, backgroundSize:"cover" }}
                >
                    <div
                        className='w-[190px] h-[190px] bg-blue-500 absolute romboClip hidden md:block top-[19.5%] right-[-15.4%] rounded-full border-white border-[8px]'
                        style={{ background: `url("${image2 ? image2 : imgDefault}")`, backgroundSize: "cover", backgroundPosition: "center" }}
                    ></div>
                    <div
                        className='w-[160px] md:w-[210px] h-[160px] md:h-[210px] bg-blue-500 absolute bg-contain romboClip md:top-[62%] top-[82%] right-[29%] md:right-[-17%] rounded-full border-white border-[8px] '
                        style={{ background: `url("${image3 ? image3 : imgDefault}")`, backgroundSize: "cover", backgroundPosition: "center" }}
                    ></div>
                </div>
                <div className='md:w-[60%] w-full md:pl-32 pl-5 md:pr-14 pr-5 md:py-10 pt-20 pb-8 flex flex-col items-start justify-center'>
                    
                    {rpdata?.dbValues?.map((item, index) => {
                        return (
                            <div key={index}>
                                <div className='w-full h-1/3 px-8 py-4'>
                                    <div className='w-full h-auto justify-start flex items-center space-x-4'>
                                        <BsArrowRightCircleFill className='text-[25px]' />
                                        <h2>{item.title}</h2>
                                    </div>
                                    <div>
                                        <p>{item.description}</p>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </>
    )

}

export default Values_5